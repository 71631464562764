'use client'
import { giphyLocalUrl } from '@/app/util/env'
import { GET } from '@/app/util/url'
import { ILoggedInUser } from 'types'
import { timeoutFetch } from 'utils/src/api/fetch'
import { getCookie } from './url'

export const SESSION_COOKIE_NAME = 'giphy_session_id'
export const CSRF_COOKIE_NAME = 'csrftoken'
export const CSRF_HEADER_NAME = 'X-CSRFToken'

export const getUserOnClient = async (): Promise<ILoggedInUser | undefined> => {
    if (typeof window === 'undefined' && !getCookie(SESSION_COOKIE_NAME, document.cookie)) {
        throw new Error('no session cookie')
    }
    const f = await timeoutFetch({ url: `${giphyLocalUrl}/login/check/`, options: GET(document.cookie) })
    const {
        result: { user },
    } = await f.json()
    return user
}
